import React from "react"
import Card from "./card"

const Articles = ({ articles }) => {
  return (
    <div className="grid lg:grid-cols-3 main-div gap-12">
      {articles.map((article, i) => {
        return (
          <>
            <Card
              article={article}
              key={`article__left__${article.node.slug}`}
            />
          </>
        )
      })}
    </div>
  )
}

export default Articles
