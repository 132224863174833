import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Layout from "./../../components/layout"
import ArticlesComponent from "./../../components/blog/articles"
import Categories from "./../../components/blog/categories"
import Footer from "../../components/footer"

const IndexPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="lg:mx-14 px-4 sm:px-6">
        <div className="flex flex-col gap-12 py-12">
          <div className>
            <p className="lg:text-6xl text-small font-semibold">
              How we think.
            </p>
          </div>
          <Categories />
        </div>
      </div>
      <div
        className="rounded-b-2xl lg:rounded-b-3xl"
        style={{ boxShadow: "0px 0px 15px 3px #0000001A" }}
      >
        <div className="lg:mx-14 px-4 sm:px-6 py-12">
          <ArticlesComponent articles={data.allStrapiArticles.edges} />
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

const query = graphql`
  {
    strapiHomepage {
      hero {
        title
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
    allStrapiArticles {
      edges {
        node {
          strapiId
          slug
          title
          image {
            url
          }
          categories {
            slug
            title
          }
          author {
            name
          }
          date(formatString: "DD MMMM, YYYY")
        }
      }
    }
  }
`

export default IndexPage
