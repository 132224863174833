import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const Categories = () => (
  <StaticQuery
    query={graphql`
      query {
        allStrapiArticlesCategories {
          edges {
            node {
              slug
              title
            }
          }
        }
      }
    `}
    render={data => (
      <div className="gap-8">
        <Link
          to="/blog"
          className="inline-block px-6 m-2 py-1 border border-black rounded-full bold"
          activeClassName="text-white bg-black"
          style={{ fontWeight: "800" }}
        >
          All Articles
        </Link>
        {data.allStrapiArticlesCategories.edges.map((category, i) => {
          return (
            <Link
              to={`/blog-categories/${category.node.slug}`}
              className="bold inline-block text-primary px-6 m-2 py-1 border border-black rounded-full hover:bg-navbar hover:text-white hover:border-none"
              activeClassName="text-white bg-black"
              style={{ fontWeight: "800" }}
            >
              {category.node.title}
            </Link>
          )
        })}
      </div>
    )}
  />
)

export default Categories
